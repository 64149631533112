<template>
  <div>
    <b-card
      no-body
      class="mb-0 px-2"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <b-form-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>registros</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filter"
                size="md"
                type="search"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
                debounce="400"
              />

            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        id="my-table"
        ref="refTablaMisTalleres"
        class="position-relative"
        :no-provider-filtering="true"
        show-empty
        head-variant="light"
        hover
        responsive
        :busy.sync="isBusy"
        :items="myProvider"
        :fields="tableColumns"
        :current-page="currentPage"
        :empty-text="'No hay registros para mostrar'"
        :empty-filtered-text="'No hay registros que coincidan con su solicitud.'"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
      >

        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong>Cargando...</strong>
          </div>
        </template>

        <template #cell(index)="data">
          {{ from + data.index }}
        </template>

        <!-- Column: comunicados -->
        <template #cell(acciones)="data">
          <b-button
            v-if="data.item.matriculado.evaluacion === 'aprobado'"
            size="sm"
            variant="outline-success"
            block
            @click="descargarCertificado(data.item.matriculado)"
          >
            <feather-icon
              icon="FileTextIcon"
              size="10"
            />
            Certificado
            <b-overlay
              :show="show"
              no-wrap
            />
          </b-button>
        </template>

        <!-- Nombre -->
        <template #cell(nombre)="data">
          <span class="text-primary"><strong>{{ data.item.nombre }}</strong></span>

        </template>

        <!-- Column: estado -->
        <template #cell(evaluacion)="data">
          <b-badge
            pill
            :variant="`light-${resolveEstadoVariant(data.item.matriculado.evaluacion)}`"
            class="text-capitalize"
          >
            {{ data.item.matriculado.evaluacion }}
          </b-badge>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando registros del {{ from }} al {{ to }} de un total de {{ totalRows }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BSpinner, BOverlay,
  BBadge, BPagination, BButton, BFormSelect,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import misTalleresStoreModule from './misTalleresStoreModule'

export default {
  components: {
    BFormSelect,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BSpinner,
    BBadge,
    BPagination,
    BButton,
    BOverlay,
  },

  data() {
    return {
      show: false,

      isBusy: false,
      from: 1,
      to: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      pageOptions: [50, 100, 300, 500],
      sortBy: 'id',
      sortDesc: false,
      // sortDirection: 'desc',
      filter: '',
      filterOn: [],

      dataConvocatoriaDetalles: {},

      tableColumns: [
        { key: 'index', label: 'N°', sortable: true },
        { key: 'nombre', label: 'Nombre', sortable: true },
        { key: 'semestre.nombre', label: 'Semestre', sortable: true },
        { key: 'profesor.name', label: 'Profesor', sortable: true },
        {
          key: 'f_inicio', label: 'FECHA Inicio', sortDirection: 'desc', sortable: true,
        },
        { key: 'f_fin', label: 'FECHA Fin', sortable: true },
        { key: 'evaluacion', label: 'Estado', sortable: true },
        { key: 'acciones', label: 'acciones' },
      ],
    }
  },

  methods: {
    actualizarTabla() {
      this.filter = null
      this.$refs.refTablaMisTalleres.refresh()
    },
    async myProvider(ctx) {
      const promise = store.dispatch('mis-talleres-module/getMisTalleres', { queryParams: ctx })

      return promise.then(response => {
        const {
          data, total, from, to,
        } = response.data
        // Pluck the array of items off our axios response
        const items = data
        this.totalRows = total || 0
        // this.from = meta.pagination.count
        this.from = from || 0
        this.to = to || 0

        return items || []
      })
    },

    descargarCertificado(item) {
      this.show = true
      this.$http.get(`/servicio-cultural-deportivo/${item.id}/certificado`,
        {
          params: { url_pdf: `${window.location.origin}/descargarPDfCertificado/${item.id}/pdf` },
          responseType: 'blob', // important
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')

          link.href = url
          link.setAttribute('download', `CertificadoDIBU${item.id}.pdf`)
          document.body.appendChild(link)
          link.click()
          this.show = false
        })
    },

  },
  setup() {
    const CONVOCATORIA_APP_STORE_MODULE_NAME = 'mis-talleres-module'

    // Register module
    if (!store.hasModule(CONVOCATORIA_APP_STORE_MODULE_NAME)) store.registerModule(CONVOCATORIA_APP_STORE_MODULE_NAME, misTalleresStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CONVOCATORIA_APP_STORE_MODULE_NAME)) store.unregisterModule(CONVOCATORIA_APP_STORE_MODULE_NAME)
    })

    const resolveEstadoVariant = status => {
      if (status === 'sin evaluar') return 'secondary'
      if (status === 'aprobado') return 'success'
      if (status === 'desaprobado') return 'warning'
      return 'primary'
    }

    return {
      misTalleresStoreModule,
      resolveEstadoVariant,

    }
  },
}
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}
</style>
